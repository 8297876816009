export default {
  orderNumber: '',
  userName: '',
  departmentName: '',
  printQuality: 0,
  onlineQuality: 0,
  subscriptions: 0,
  socialEngagement: 0,
  videoQuality: 0,
  articlesQuantity: 0,
  videoQuantity: 0,
  longTermOnlineQuality: 0,
  total: 0
}
