export default {
  articles: 0,
  photostory: 0,
  quiz: 0,
  images: 0,
  videos: 0,
  pageViews: 0,
  videoViews: 0,
  pageViewsNormalized: 0,
  avgArticleCompletion: 0,
  avgDailyUsers: 0,
  avgGalleryCompletion: 0,
  avgTimeSpentPerUserArticle: 0,
  avgTimeSpentPerUserGallery: 0,
  avgDailyUsersOrganic: 0,
  longTermPageViewsNormalized: 0,
  longTermVideoViews: 0
}
