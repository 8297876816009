const messages = {
  byAverage: 'PV and VV by average',
  kpi_settings: 'KPI Settings',
  kpi_settings_department: 'KPI settings for the department',
  kpi_basic_view: 'Comparison to the target',
  kpi_averages_view: 'Comparison to the averages',
  kpi_users_view: 'Department view per user',
  kpi_type: 'KPI Type',
  vs_kpi: 'vs. KPI',
  department_overall: 'Overall',
  department: 'Department',
  product: 'Product',
  comparison_week: 'Week',
  kpi_current: 'Real completion',
  kpi_target: 'KPI settings',
  kpi_percentage: 'KPI percentual',
  kpi_median: 'Dept. Median',
  kpi_fulfilment: 'KPI Fulfilment',
  articles_breakdown: 'Article breakdown',
  viewsStructure: 'PV Structure - Typ obsahu',
  print_articles: 'Print articles',
  digital_articles: 'Digital articles',
  videos: 'Videos',
  video_types: 'Video types',
  performance: 'Performance',
  weights_type: 'Weights type',
  product_performance: 'Product performance',
  department_performance: 'Department performance',
  base_data: 'Base data',
  user_data_not_found: 'No data available for selected author.',
  number_of_records: 'Number of records:',
  order_number: 'Order',
  info_mark: '*normalized PV - conversion of PV according to the difference in monetization potential between article and gallery PV, at a ratio of: <b>1 article page view = 14 gallery PV</b>',
  groupPerformance: {
    orderNumber: 'Order Number',
    userName: 'Author',
    departmentName: 'Department`',
    printQuality: 'Print quality',
    onlineQuality: 'Online quality',
    subscriptions: 'Subscriptions',
    socialEngagement: 'Social engagement',
    videoQuality: 'Video quality',
    articlesQuantity: 'Article quality',
    videosQuantity: 'Video quantity',
    nativeQuantity: 'Native quantity',
    longTermOnlineQuality: 'Quality Online Longterm',
    total: 'Performance'
  },
  kpi_parameters_tooltip: {
    articles: 'Number of published articles for selected period',
    photostory: '',
    quiz: '',
    images: '',
    videos: '',
    pageViews: '',
    pageViewsNormalized: 'Number of PV on the articles published in selected period, not from historical',
    videoViews: 'Number of VV on the Videos published in selected period, not VV from history',
    videoArticles: '',
    avgDailyUsers: '',
    avgTimeSpentPerUserArticle: '',
    avgArticleCompletion: '',
    avgTimeSpentPerUserGallery: '',
    avgGalleryCompletion: '',
    avgDailyUsersOrganic: '',
    longTermPageViewsNormalized: '',
    longTermVideoViews: ''
  },
  kpi_parameters: {
    articles: 'Articles',
    photostory: 'Photostories',
    quiz: 'Quizes',
    images: 'Pictures',
    videos: 'Uploaded videos',
    pageViews: 'Page Views',
    pageViewsNormalized: 'Page views (*normalized)',
    videoViews: 'Video views',
    avgDailyUsers: 'Average number of users (RUs)',
    avgTimeSpentPerUserArticle: 'Average time spent on article',
    avgArticleCompletion: 'Average article completion rate',
    avgTimeSpentPerUserGallery: 'Average time spent in gallery',
    avgGalleryCompletion: 'Average gallery completion rate',
    avgDailyUsersOrganic: 'Average daily organic traffic',
    longTermPageViewsNormalized: 'Total Long-Term PageViews (*normalised)',
    longTermVideoViews: 'Total Long-term VideoViews'
  },
  realTimeStats: {
    pageViews: 'PageViews',
    realUsers: 'Real Users',
    videoViews: 'VideoViews ',
    avgTimeSpent: 'Average Time Spent (ATS)'
  },
  videoArticles: 'Covered with video',
  performanceItems: {
    pageViewCnt: 'Page views',
    imagesCnt: 'Images',
    videosCnt: 'Videos',
    fbShares: 'FB shares',
    disqusComments: 'Disqus comments',
    videoViewCnt: 'Video views',
    photostoryCnt: 'Photostory',
    quizCnt: 'Quizes',
    videoCnt: 'Videos',
    internalCnt: 'Internal',
    externalCnt: 'External',
    nativeCnt: 'Native',
    prCnt: 'PR',
    editorialCnt: 'Editorial',
    galleryViewCnt: 'Gallery views',
    viewCnt: 'Views',
    engagedTime: 'Engaged time',
    articleCnt: 'Articles',
    articleEditCnt: 'Articles edits'
  },
  toolTip: {
    clusterDesc: '<b>Cluster</b> is categorised titles',
    weekSelect: '<b>Callendar week selection</b><br>Select a date for which a week should be selected',
    orderNumber: 'Defined by overall performance',
    printQuality: 'Print articles in 3 categories: A/B/C',
    onlineQuality:
      `<b>Combination of basic metrics:</b><br>
      Total normalized PV<br>
      Number of users<br>
      Time spent in article<br>
      Article readability<br>
      Time spent in gallery<br>
      Gallery completion<br>
      Organic traffic`,
    subscriptions: 'Subscriptions value (Trend only)',
    socialEngagement:
      `<b>Combination of basic metrics:</b><br>
      Number of comments<br>
      Number of Facebook shares`,
    videoQuality:
      `<b>Combination of basic metrics:</b><br>
      Number of video views<br>
      Views over half the length of the video`,
    articlesQuantity: 'P1/P2 author & agency, Online author, From print, Agency, Copy from another, Quiz & Photostory',
    videoQuantity: 'Number of video types: Author, Slideshow, Social, Agency',
    longTermOnlineQuality:
      `<b>Combination of basic metrics:</b><br>
      Long-Term PageViews (normalised)<br>
      Long-term VideoViews<br>
      Average daily organic traffic`,
    total: 'Total performance of author',
    pageViewsCnt: 'Number of Page Views (PageViews with GalleryViews)',
    videosCnt: 'Number of videos in the articles',
    printType: 'P1/P2 author, P1/P2 agency',
    actionArticleDetail: 'Articles detail',
    suggestedValue: 'Value calculated as average from previous 12 months',
    nmh_kpi_october_2024_adjustment_message: `Due to reconstruction work on the Eagle Admin CMS,<br>
      <b>NMH KPIs for October 2024 are not included in the annual evaluation.</b><br>
      Budget KPIs are generally reduced by 30% and are for informational purposes only.<br>`
  },
  timeGroupType: 'Report type',
  month: 'Month',
  week: 'Week',
  monthly: 'Monthly',
  weekly: 'Weekly',
  language: 'en',
  save: 'Save',
  difference: 'Difference',
  add: 'Add',
  remove: 'Remove',
  user: 'User',
  author: 'Author',
  viewsStructureByDepartment: 'PV structure - by departments',
  cluster: 'Cluster',
  dateFrom: 'From',
  dateTo: 'To',
  average: 'average',
  department_average: 'Department`s average',
  notAvailable: 'N/A',
  weekShort: 'w',
  gcpData: 'GCP Data',
  historicalPerformanceButton: {
    pageViews: 'PVs by author',
    videoViews: 'VVs by author',
    onlineArticles: 'Online articles by author',
    p1Articles: 'Print P1 by author',
    p2Articles: 'Print P2 by author',
    publishedVideos: 'Published videos by author',
    createdVideos: 'Created videos by author',
    imagesInArticles: 'Used images by author'
  },
  clusterViewMetricsDescription: {
    pageViews: 'Average Page Views',
    videoViews: 'Average Video Views',
    onlineArticles: 'Average Online Articles',
    p1Articles: 'Average Print P1 Articles',
    p2Articles: 'Average Print P2 Articles',
    publishedVideos: 'Average Published Videos',
    createdVideos: 'Average Created Videos',
    imagesInArticles: 'Average Images in the Articles'
  },
  departmentViewMetricsDescription: {
    pageViews: 'Total Page Views',
    videoViews: 'Total Video Views',
    onlineArticles: 'Total Online Articles',
    p1Articles: 'Total Print P1 Articles',
    p2Articles: 'Total Print P2 Articles',
    publishedVideos: 'Total Published Videos',
    createdVideos: 'Total Created Videos',
    imagesInArticles: 'Total Images in the Articles'
  },
  source: 'Source: Eagle-DON',
  productArticlesComposition: 'Articles composition per Products',
  galleries: 'Galleries',
  articles: 'Articles',
  period: 'Period',
  suggestedValue: 'Suggested value',
  allProducts: 'The whole {company}',
  longLoadingWarning: 'Loading of data can take up to 5 minutes. Do not leave the page while it is loading.',
  ai: {
    articleCountTitle: 'Number of articles - AI Assistance',
    pageViewsCountTitle: 'PV structure - AI Assistance',
    articleSourceTitle: 'Article source - AI Assistance',
    withAi: 'With AI',
    withoutAi: 'Without AI',
    articleSourceAuthor: 'Internal author',
    articleSourceAuthorAi: 'Internal author with AI',
    articleSourceAgency: 'Agency',
    articleSourceAgencyAi: 'Agency with AI',
    articleSourceFromPrint: 'Copied from print',
    articleSourceFromPrintAi: 'Copied from print with AI',
    articleSourceTransferred: 'Transferred',
    articleSourceTransferredAi: 'Transferred with AI'
  }
}

export default messages
